import React, { useState, useEffect } from 'react';
import goldBarImg1 from './gold1.png';
import goldBarImg2 from './gold2.png';
import goldBarImg3 from './gold3.png';
import goldBarImg4 from './gold4.png';
import goldBarImg5 from './gold5.png';

const goldImages = [goldBarImg1, goldBarImg2, goldBarImg3, goldBarImg4, goldBarImg5];

// Define the GoldBar component here
const GoldBar = ({ style, imgSrc }) => {
  return <img src={imgSrc} alt="gold bar" style={style} />;
};

const Background = () => {
    const [goldBars, setGoldBars] = useState([]);

    useEffect(() => {
      const interval = setInterval(() => {
        const key = Math.random();
        const imgSrc = goldImages[Math.floor(Math.random() * goldImages.length)];
        const posX = Math.random() * (window.innerWidth - 30);

        // Adjust scale for smaller size and add random rotation
        const scale = 0.2 + Math.random() * 0.3; // Even smaller sizes
        const rotation = -45 + Math.random() * 90; // Random rotation between -45 and 45 degrees

        const duration = 3 + Math.random() * 4; // Slower and more varied falling speed

        const style = {
          position: 'fixed',
          top: '-100px',
          left: `${posX}px`,
          transform: `scale(${scale}) rotate(${rotation}deg)`,
          animation: `fall ${duration}s linear`,
          zIndex: -1, // Ensure gold bars fall behind the main content
        };
        

        setGoldBars(goldBars => [...goldBars, <GoldBar key={key} style={style} imgSrc={imgSrc} />]);

        // Optionally limit the number of gold bars if needed
        if (goldBars.length > 30) { // Reduced max count for less crowding
          setGoldBars(goldBars => goldBars.slice(1));
        }
      }, 1000); // Slowed down generation rate for less frequency

      return () => clearInterval(interval);
    }, [goldBars.length]);

    return <>{goldBars}</>;
};

export default Background;
