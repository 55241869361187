import React, { useEffect, useState } from 'react';
import Background from './Background';
import './App.css';
import logoImg from './logo.png'; 
import goldBarCursor from './GoldBars.cur'; 

function App() {
  // State to manage the copy confirmation animation
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    const setCustomCursor = (e) => {
      e.target.style.cursor = `url(${goldBarCursor}), auto`;
    };
    const applyCustomCursorToLinksAndButtons = () => {
      document.querySelectorAll('a, button').forEach(el => {
        el.addEventListener('mouseover', setCustomCursor);
        el.addEventListener('mouseout', setCustomCursor);
      });
    };

    applyCustomCursorToLinksAndButtons();

    return () => {
      document.querySelectorAll('a, button').forEach(el => {
        el.removeEventListener('mouseover', setCustomCursor);
        el.removeEventListener('mouseout', setCustomCursor);
      });
    };
  }, []);

  const appStyle = {
    cursor: `url(${goldBarCursor}), auto`,
  };

  const [copyCount, setCopyCount] = useState(0); // Use a counter to ensure a change

  const copyToClipboard = () => {
    navigator.clipboard.writeText("0xeD133b299FBE25D0B44364fD12b8bd6f78249717").then(() => {
      setCopySuccess('Copied!');
      setCopyCount(copyCount + 1); // Increment counter to force re-render
      setTimeout(() => {
        setCopySuccess('');
      }, 2000); // Match CSS animation duration
    }, () => {
      setCopySuccess('Failed to copy');
    });
  };
  
  
  return (
    <div style={appStyle} className="App">
      <div className="relative min-h-screen bg-golden-gradient">
        <Background />
      <div className="relative flex justify-center items-center min-h-screen z-10">
        <div className="absolute left-0 top-1/4 transform -translate-x-full">
        <div className="button-container">
  <a href="https://dapp.peetorspliff.com/" target="_blank" rel="noopener noreferrer" className="block mb-4 px-8 py-4 bg-yellow-500 text-black rounded hover:bg-yellow-600 transition duration-300 text-2xl font-bold">DAPP</a>
  <a href="https://docs.peetorspliff.com/" target="_blank" rel="noopener noreferrer" className="block px-8 py-4 bg-yellow-500 text-black rounded hover:bg-yellow-600 transition duration-300 text-2xl font-bold">DOCS</a>
</div>

        </div>
        <img src={logoImg} alt="Logo" className="logo-swing absolute left-0 transform -translate-x-1/2 -translate-y-1/2" />

        <div className="text-center p-8 bg-black bg-opacity-50 rounded-lg shadow-lg z-20">
          <h1 className="text-4xl font-bold text-yellow-300 mb-4">$GOLD</h1>
          <h2 className="text-2xl font-semibold text-yellow-200 mb-2">Peetor Spliff</h2>
          <h3 className="text-xl text-yellow-100 mb-4">a new era uv prospurity ERC420</h3>
          <p className="text-yellow-50 mb-6">diev into n world wure wlt acumulashun iz not jus n drm, bat n tngibel rlity.</p>
          <div className="flex justify-center space-x-4">
            <a href="#lolnolink" className="px-4 py-2 bg-yellow-500 text-black rounded hover:bg-yellow-600 transition duration-300">$bui golD</a>
            <a href="https://t.me/peetorsfortknox" className="px-4 py-2 bg-yellow-500 text-black rounded hover:bg-yellow-600 transition duration-300">Joyn telegram</a>
            
          </div>
          <div className="bottom-container">
  <div className="flex items-center">
    {/* Left logos with links */}
    <a href="https://dexscreener.com/base/0xbc622ae0e58bfbb1e3125ef7bc1d354030d6d4e6" target="_blank" rel="noopener noreferrer">
      <img src="dexscreener.png" alt="DexScreener Logo" className="link-logo" />
    </a>
    <a href="https://www.dextools.io/app/en/base/pair-explorer/0xbc622ae0e58bfbb1e3125ef7bc1d354030d6d4e6?t=1710525410174" target="_blank" rel="noopener noreferrer">
      <img src="dextools.png" alt="DexTools Logo" className="link-logo" />
    </a>
  </div>

  {/* Middle smart contract address button */}
  <button onClick={copyToClipboard} className="copy-button">
    <span>📋</span>
    <span>0xeD133b299FBE25D0B44364fD12b8bd6f78249717</span>
  </button>
  {copySuccess && <p key={copyCount} className="copy-feedback">{copySuccess}</p>}

  {/* Right side base logo and ERC420 text */}
  <div className="right-section">
    <img src="base.png" alt="Base Logo" />
    <img src="erc420.png" alt="ERC420 Logo" />
  </div>
</div>

        </div>
      </div>
    </div>
    </div>

  );
}

export default App;
